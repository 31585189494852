import React, { useEffect, useState } from 'react'
import { Button, Grid, TextField, Paper, Drawer, IconButton, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, imageMedia, videoTypes } from '../../constants';
import { globalGetService, globalPostService } from '../../utils/globalApiServices';
import { checkApiStatus } from '../../utils';

export default function GlobalSearchDropdown({onFieldChange, filter}) {
    const [assetList, setAssetList]= useState([])
    const [usersList, setUsersList]= useState([])
    const [fileTypes, setFileTypes]= useState([])
   useEffect(()=>{
    getUsersList()
    getAssetList()
    getFileTypes()
   },[])
   const getAssetList =()=>{
    globalGetService(`console/records-asset-list/?switcher=true&all_assets=true&billable_asset=true`)
    .then(response=>{
        setAssetList(response.data.data?.asset)
    })
   }
   const getFileTypes = () => {
    globalPostService('console/get-constants/',{constant_types:['file_extension_drive']})
    .then(response => {
        if(checkApiStatus(response)){
            setFileTypes(response.data.data)
        }
    })
}
   const getUsersList =()=>{
    globalGetService(`/records/v2/action-dock/get-files/?get_only_users=true&tab=in_queue`)
    .then(response=>{
        setUsersList(response.data.data)
    })
   }
    return (
        <Grid container spacing={3}>
            <Grid item md={12} >
                <Autocomplete
                    id="file_type"
                    options={fileTypes? fileTypes:[]}
                    getOptionLabel={option => option.label}
                    value={filter.file_type}
                    onChange={(e, value) => onFieldChange('file_type', value)}
                    renderInput={params => <TextField {...params} variant='outlined' label="Type" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </Grid>
            <Grid item md={12} >
                <Autocomplete
                    id="search_only"
                    options={[{ label: 'Everything', value: 'none' }, { label: 'File and Folder Names', value: 'name' }, { label: 'File Content', value: 'text' }]}
                    getOptionLabel={option => option.label}
                    value={filter.search_only}
                    onChange={(e, value) => onFieldChange('search_only', value)}
                    renderInput={params => <TextField {...params} variant='outlined' label="Search Within" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </Grid>
            {/* <Grid item md={12} >
                <Autocomplete
                    id="size"
                    options={[{ label: 'Any Size', value: '' }, { label: '0-1 MB', value: '' }, { label: '1-5 MB', value: '' }, { label: '1GB+', value: '' }]}
                    getOptionLabel={option => option.label}
                    value={filter.size}
                    onChange={(e, value) => onFieldChange('size', value)}
                    renderInput={params => <TextField {...params} variant='outlined' label="Size" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </Grid> */}
            <Grid item md={12} >
                <Autocomplete
                    id="assets"
                    options={ assetList? assetList: []}
                    getOptionLabel={option => option.unique_name}
                    value={filter.assets}
                    onChange={(e, value) => onFieldChange('assets', value)}
                    renderInput={params => <TextField {...params} variant='outlined' label="Assets" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </Grid>
            <Grid item md={12} >
                <Autocomplete
                    id="user_id"
                    options={ usersList? usersList:[]}
                    getOptionLabel={option => option.name}
                    value={filter.user_id}
                    onChange={(e, value) => onFieldChange('user_id', value)}
                    renderInput={params => <TextField {...params} variant='outlined' label="Created By" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </Grid>
            {/* <Grid item md={12} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        required
                        margin="normal"
                        id="from_date"
                        label="Created Date From"
                        placeholder="Date From"
                        format={fieldDateFormat}
                        fullWidth
                        disableFuture
                        inputVariant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={filter.from_date ? filter.from_date : null}
                        onChange={(data, value) => onFieldChange('from_date', moment(data).format(backendDateFormat))}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item  md={12} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        required
                        margin="normal"
                        id="to_date"
                        placeholder="Date To"
                        format={fieldDateFormat}
                        fullWidth
                        disableFuture
                        label="Created Date to"
                        inputVariant='outlined'
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={filter.to_date ? filter.to_date : null}
                        onChange={(data, value) => onFieldChange('to_date', moment(data).format(backendDateFormat))}
                    />
                </MuiPickersUtilsProvider>
            </Grid> */}
        </Grid>
    )
}
