import { Tooltip } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import { castleLakeInstance, lessorCheckInstance } from "../../shared_elements/components";
import { checkPermission } from "../../utils";

export const dmRoomListHd = [
  {label:'Name', sortOption:true, id:'name'},
  // {label:'Page Count' , sortOption: true, tooltip:true,id:'page_count'},
  {label:'Size / Count', sortOption: true,  id:'size'},
  // {label:'Created By', sortOption:true, id:'created_by'},
  {label:'Modified At', sortOption:true, id:'modified_at'},
  {label:'OCR Status', sortOption:true, id:'ocr_status'},
  {label:'Action', sortOption:false},
];
export const dmKitsHd = [
  {label:'Name', sortOption:false},
  {label:'Size', sortOption:false},
  {label:'Action', sortOption:false, actionCell: true},
];
export const miniPackHd = [
  {label:'Name', sortOption:false},
  {label:'Count', sortOption:false},
];
export const commentDetailHd = [
  {label:'Assets', sortOption:false},
  {label:'Created Date', sortOption:false},
  {label:'Status', sortOption:false,},
  {label:'Action', sortOption:false,},
];
export const commentDetaiFilterOps = {
  'comment':{
    'inputType': 'text',
    'title': 'Comment',
},
  "start_date": {
    "inputType": 'date',
    "placeholder": 'Date From',
    "title": 'Date From',
  },
  "end_date": {
    "inputType": 'date',
    "placeholder": 'Date to',
    "title": 'Date to',
  },
'status':{
    'inputType': 'dropdown',
    'title': 'Status',
    'options':[{label:'Open',value:'0'},{label:'Resolved',value:'1'}],
    'labelKey':'label',
    'valueKey':'value'        
}
};
export function toggleBulkOpsFn(flag, type, id=null){
  const { dataKits,  } = this.state;
  if(type === 'all'){
    this.setState(previous => ({
      ...previous,
      bulkOperation:{
        ...previous.bulkOperation,
        ids:flag ? dataKits.list.map(item => item.uuid) :[]
      }
    }))
  }else{
    this.setState(previous => ({
      ...previous,
      bulkOperation:{
        ...previous.bulkOperation,
        ids:flag ? [...previous.bulkOperation.ids, id] : previous.bulkOperation.ids.filter(item => item !== id)
      }
    }))
  }
}
export function applyFilterFn(keyParam, value){
  this.setState(prevState => ({
    ...prevState,
    applyingFilter: {
      ...prevState.applyingFilter,
      [keyParam]: value
    }
  }))
}
export function onChangeBulkOps(title){
  this.setState(prevState => ({
    ...prevState,
    bulkOperation:{
      ...prevState.bulkOperation,
      modal:true,
      title:title,
      multi:true
    }
  }))
}
export function listSingleActionFn(title, id){
  this.setState(prevState => ({
    ...prevState,
    bulkOperation:{
      ...prevState.bulkOperation,
      ids:[id],
      modal:true,
      title:title,
      multi:false
    }
  }))
}

export const addNewIssueObj = {
  title:'',
  description:'',
  priority:null,
  status:null,
  assignee:null,
  started_date:null,
  estimated_completion_date:null,
  category:null,
  ata:'',
  cycles_remaining:null,
  part_number:'',
  serial_number:'',
  install_date:null,
  position:'',
  requirement_remarks:'',
  background_remarks:'',
  attachment:[],
  disposition:0,
  record_uuid:null

}
export const result = {
  "data": {
      "id": 70683,
      "gap_report_section": {
          "id": 1,
          "name": "Final Summaries and Statements"
      },
      "work_order": "zZrMIs",
      "description": "<p>lorem ispsum has dorem it</p>",
      "part_number": "pn123",
      "serial_number": "sl123",
      "created_by": {
          "id": 2014,
          "name": "Girish K V",
          "designation": "null",
          "slug": "HArEoE"
      },
      "cycles_remaining": 2000,
      "disposition": 0,
      "ata": "1",
      "started_date": "2023-10-10",
      "estimated_completion_date": "2023-10-20",
      "unique_code": "AIN-001",
      "requirement_remarks": "<p>DiscrepancyDiscrepancy</p>",
      "background_remarks": "<p>DiscrepancyDiscrepancy BC</p>",
      "files": [],
      "files_size": "0",
      "operator_comments": null,
      "priority": 0,
      "tags": [],
      "title": "Test G Title",
      "status_update_by": [
          {
              "id": 554,
              "from_status": null,
              "to_status": {
                  "value": 0,
                  "label": "Open"
              },
              "updated_by": {
                  "id": 2014,
                  "name": "Girish K V",
                  "email": "girish.kv@acumen.aero",
                  "slug": "HArEoE"
              },
              "updated_at": "2023-10-10T05:35:11.691558Z"
          }
      ],
      "position": "22",
      "install_date": "2023-10-10",
      "operator_2_comments": null,
      "acumen_comments": null,
      "assignee": {
          "id": 363,
          "name": "Harshith Somaiah BT",
          "designation": "Data Analyst",
          "slug": "JXKNAN"
      },
      "assigned_date": "2023-10-10",
      "updated_by": {},
      "updated_at": "2023-10-10T05:35:11.711863Z",
      "status": {
          "value": 0,
          "label": "Open"
      }
  },
  "statusCode": 200,
  "status": "SUCCESS",
  "message": "Fetched Gap Report Successfully"
}
export const onCheckActionPermission = (dataroom, navLength, moveUuid, techUuid) => {
  if ((dataroom?.navigation?.length == 1 || navLength==1) && (castleLakeInstance|| lessorCheckInstance )) {
    if(moveUuid && (moveUuid == techUuid)){
      if ( checkPermission('records', 'data_room', 'RFA')) {
        return true
      }
      else {
        return false
      }
    }else{
      if ( checkPermission('records', 'data_room', 'RFA')) {
        return true
      }
      else {
        return false
      }
    }
  }
  else {
    return true
  }
}