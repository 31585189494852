import DataRoom from './containers';
import DataBucket from './containers/DataBucket';
import DataKits from './containers/DataKits';
import DataMiniPack from './containers/DataMiniPack';
import MiniPackDetail from './containers/MiniPackDetail';
import GlobalSearchResult from './containers/GlobalSearchResult';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
import CommentDetail from './containers/CommentDetail';
// import CommentList from './components/CommentList';
import DataRoomIssues from './containers/DataRoomIssues'
import DRIssueDetail from './containers/DRIssueDetail'
import DataCheckList from './containers/DataCheckList';
import DataCheckListDetail from './containers/DataCheckListDetail';
import MiniPackDataRoomConfig from './containers/MiniPackDataRoomConfig';
import InitialConfig from './components/InitialConfig';
export const dataRoomRoutes = [
  {
    path: '/:assetType/:assetSlug/data-room/:type/:uuid',
    component: PageLayout(UserRoleAccess(DataRoom, ['records', 'data_room', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DataRoom',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/:type/bucket',
    component: PageLayout(DataBucket, {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DataBucket',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/:type/kit/:kitType/:uuid',
    component: PageLayout(UserRoleAccess(DataKits, ['records', 'category', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DataKits',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/:type/mini-pack/list',
    component: PageLayout(UserRoleAccess(DataMiniPack, ['records', 'mini-pack', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DataMiniPack',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/:type/mini-pack/detail/:uuid',
    component: PageLayout(UserRoleAccess(MiniPackDetail, ['records', 'mini-pack', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'MiniPackDetail',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room-mini-pack/:configSlug/:configId',
    component: PageLayout(UserRoleAccess(MiniPackDataRoomConfig, ['records', 'mini-pack', 'R']), {apps: 'MiniPackDataRoomConfig', layoutPhase: 2}),
    key: 'MiniPackSection',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/mini-pack',
    component: PageLayout(UserRoleAccess(InitialConfig, ['records', 'mini-pack', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'MiniPackDetail',
    apps:'records'
  },
  {
    path: '/data-room/search',
    component: PageLayout(GlobalSearchResult, {apps: 'Home', layoutPhase: 1}),
    key: 'GlobalSearchResult',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/records_comments',
    component: PageLayout(UserRoleAccess(CommentDetail, ['records', 'comments_page', 'R']), {apps: 'CommentList', layoutPhase: 2}),
    key: 'CommentDetail',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/issues',
    component: PageLayout(UserRoleAccess(DataRoomIssues, ['records', 'records_issue', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DataRoomIssues',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/issues/view/:slug',
    component: PageLayout(UserRoleAccess(DRIssueDetail, ['records', 'records_issue', 'R']), {apps: 'DataRoom', layoutPhase: 2}),
    key: 'DRIssueDetail',
  },
  {
    path: '/:assetType/:assetSlug/data-room/checklist',
    component: PageLayout(UserRoleAccess(DataCheckList, ['records', 'inspection_checklist', 'R']), {apps: 'DataCheckList', layoutPhase: 2}),
    key: 'DataCheckList',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/data-room/checklist/view/:slug',
    component: PageLayout(UserRoleAccess(DataCheckListDetail, ['records', 'inspection_checklist', 'R']), {apps: 'DataCheckListDetail', layoutPhase: 2}),
    key: 'DataCheckListDetail',
    apps:'records'
  },
]
