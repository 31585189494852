import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, Grid, TextField, Paper, Drawer, IconButton, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import MenuIcon from '@material-ui/icons/Menu';
import { Autocomplete } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { GlobalSearchList, RenameFileFolderModal } from '../components';
import { fieldDateFormat, backendDateFormat, imageMedia, videoTypes } from '../../../constants';
import { PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { checkApiStatus } from '../../../utils';
import { globalFilterRecordsSearchAc, globalRecordsSearchAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { downloadGlobalSearchApi, onRenameSearchDataApi, onDeleteSearchDataApi } from '../apiServices';
import { getLocalStorageInfo, removeEmptyKey } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import * as actions from '../../../shared_elements/actions'
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
const queryString = require('query-string');
const searchOptions =[{ label: 'Everything', value: 'none' }, { label: 'File and Folder Names', value: 'name' }, { label: 'File Content', value: 'text' }]
const globalFilterObj = {
  size: null,
  file_type: null,
  search_only: null,
  assets: null,
  user_id: null,
  from_date: null,
  to_date: null,
}
class GlobalSearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      filter: globalFilterObj,
      dataRename: {
        modal: false,
        data: null
      },
      bulkOperation: {
        ids: null,
        modal: false
      },
      open:false,
      fileTypes:[],
      assetList:[],
      usersList:[],
      querryStr:{}
    };
    this.downloadGlobalSearchApi = downloadGlobalSearchApi.bind(this);
    this.onRenameSearchDataApi = onRenameSearchDataApi.bind(this);
    this.onDeleteSearchDataApi = onDeleteSearchDataApi.bind(this);
  }
  componentDidMount() {
    const { searchLoader } = this.props;
    let parsedQuery = {};
      const searchParams = new URLSearchParams(queryString.parse(this.props.location.search));
      for (const [key, value] of searchParams.entries()) {
        parsedQuery[key] = isNaN(value) ? value : Number(value);
      }
      this.props.globalRecordsSearchFn({ page: 1, per_page: 20, ...parsedQuery })
      setTimeout(()=>{
        this.setState({querryStr: Object.assign({},parsedQuery)})
      },300)
    this.getUsersList()
    this.getAssetList()
    this.getFileTypes()
    this.props.getPlatformConstants();
    trackActivity('Page Visited', { page_title: 'Global Search Results' })
    if(parsedQuery?.search_only){
      this.setState(prevState => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          search_only: searchOptions?.find(item => item?.value === parsedQuery?.search_only)
        }
      }))
    }
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [keyParam]: value
      }
    }))
  }
  moveNextPage = () => {
    const { recordsSearch } = this.props;
    let nextPage = parseInt(recordsSearch.pagination.page) + 1;
    const { filter } = this.state;
    let filterObj = Object.assign({}, removeEmptyKey(filter));
    if (filterObj.file_type) {
      filterObj = {
        ...filterObj,
        file_type: filter.file_type.value ? filter.file_type.value : this.state.querryStr?.file_type ?this.state.querryStr?.file_type : null
      }
    }
    if (filterObj.search_only) {
      filterObj = {
        ...filterObj,
        search_only: filter.search_only.value
      }
    }
    if (filterObj.assets) {
      filterObj = {
        ...filterObj,
        assets: filter.assets.id,
        asset_type: filter.assets?.asset_type || null,
      }
    }
    if (filterObj.user_id) {
      filterObj = {
        ...filterObj,
        user_id: filter.user_id.id
      }
    }
    this.props.globalRecordsSearchFn({ q: queryString.parse(this.props.location.search)?.q, ...filterObj,page: nextPage, per_page: 20, }, 'loadmore')
  }
  applySearchFilter = () => {
    const { filter } = this.state;
    let filterObj = Object.assign({}, removeEmptyKey(filter));
    if (filterObj.file_type) {
      filterObj = {
        ...filterObj,
        file_type: filter.file_type.value
      }
    }
    if (filterObj.search_only) {
      filterObj = {
        ...filterObj,
        search_only: filter.search_only.value
      }
    }
    if (filterObj.assets) {
      filterObj = {
        ...filterObj,
        assets: filter.assets.id,
        asset_type: filter.assets?.asset_type || null,
      }
    }
    if (filterObj.user_id) {
      filterObj = {
        ...filterObj,
        user_id: filter.user_id.id
      }
    }
    this.props.globalRecordsSearchFn({ ...filterObj, page: 1, per_page: 20, q: queryString.parse(this.props.location.search).q })
  }
  saveFileFolderName = (name) => {
    if (name.trim().length) {
      const { dataRename } = this.state;
      let payload = {
        type: dataRename.data.type,
        uuid: dataRename.data.uuid,
        new_name: name
      }
      this.setState({ pageLoader: true });
      this.onRenameSearchDataApi(this.props, payload)
        .then(response => {
          this.setState({ pageLoader: false });
          if (checkApiStatus(response)) {
            this.setState(prevState => ({
              ...prevState,
              dataRename: {
                modal: false,
                data: null
              }
            }));
            this.props.onUpdateRenameList(payload);
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            trackActivity('File/Folder Renamed', {
              page_title: 'Global Search Results',
              item_type: response.data.data.type,
              item_name: response.data.data.name,
              item_location: response.data.data.location,
              item_extension: response.data.data.extension ? response.data.data.extension : '',
              asset_id: response.data.data.asset_id
            })
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.props.enqueueSnackbar('Pease enter the name', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }
  onDeleteSearchData = () => {
    const { bulkOperation } = this.state;
    let payload = {
      delete_status: 1,
      folder_uuid: []
    }
    if (bulkOperation.ids.type === 'file') {
      payload = {
        ...payload,
        file_uuid: [bulkOperation.ids.uuid]
      }
    } else {
      payload = {
        ...payload,
        folder_uuid: [bulkOperation.ids.uuid]
      }
    }
    this.setState({ pageLoader: true });
    this.onDeleteSearchDataApi(this.props, payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState(prevState => ({
            ...prevState,
            bulkOperation: { modal: false, ids: null }
          }));
          this.props.onDeleteSearchRecords(bulkOperation.ids);
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
  getAssetList = () => {
    globalGetService(`console/records-asset-list/?switcher=true&all_assets=true&billable_asset=true`)
      .then(response => {
        this.setState({ assetList: response.data.data?.asset})
        if(this.state.querryStr?.assets){
        this.setState(prevState => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            assets: response.data.data?.asset.find(item => item?.id === this.state.querryStr?.assets)
          }
        }))
      }
    })
  }
  getFileTypes = () => {
    globalPostService('console/get-constants/', { constant_types: ['file_extension_drive'] })
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ fileTypes: response.data.data })
        if(this.state.querryStr?.file_type){
          this.setState(prevState => ({
            ...prevState,
            filter: {
              ...prevState.filter,
              file_type: response.data.data.find(item => item.value == this.state.querryStr?.file_type)

            }
          }))
        }
        }
      })
    }
    getUsersList =()=>{
      globalGetService(`/records/v2/action-dock/get-files/?get_only_users=true&tab=in_queue`)
      .then(response=>{
        this.setState({usersList:response.data.data})
        if(this.state.querryStr?.user_id){
        this.setState(prevState => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            user_id: response.data.data?.find(item => item?.id === this.state.querryStr?.user_id)
          }
        }))
      }
    })
   }
  render() {
    const { pageLoader, filter, dataRename, bulkOperation, open, fileTypes, assetList, usersList, querryStr } = this.state;
    const { searchLoader, recordsSearch, recordsFilter, pltConstants, loadMore } = this.props;
    return (
      <section className="global-search-section">
        <div className="heading">
          <h2>Search Results ({recordsSearch.pagination && recordsSearch.pagination.total ? recordsSearch.pagination.total : 0})</h2>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <div className="search-params">
              <p>To fine tune the result, apply advance filter:</p>
              <ul className="list-unstyled">
                <li>
                  <Autocomplete
                    id="file_type"
                    options={fileTypes || []}
                    getOptionLabel={option => option.label}
                    value={filter.file_type}
                    onChange={(e, value) => this.onFieldChange('file_type', value)}
                    renderInput={params => <TextField {...params} label="Type" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li>
                  <Autocomplete
                    id="search_only"
                    options={searchOptions}
                    getOptionLabel={option => option.label}
                    value={filter.search_only}
                    onChange={(e, value) => this.onFieldChange('search_only', value)}
                    renderInput={params => <TextField {...params} label="Search Within" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li style={{ display: 'none' }}>
                  <Autocomplete
                    id="size"
                    options={[{ label: 'Any Size', value: '' }, { label: '0-1 MB', value: '' }, { label: '1-5 MB', value: '' }, { label: '1GB+', value: '' }]}
                    getOptionLabel={option => option.label}
                    value={filter.size}
                    onChange={(e, value) => this.onFieldChange('size', value)}
                    renderInput={params => <TextField {...params} label="Size" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li>
                  <Autocomplete
                    id="assets"
                    options={assetList|| []}
                    getOptionLabel={option => option.unique_name}
                    value={filter.assets}
                    onChange={(e, value) => this.onFieldChange('assets', value)}
                    renderInput={params => <TextField {...params} label="Assets" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li>
                  <Autocomplete
                    id="user_id"
                    options={usersList || []}
                    getOptionLabel={option => option.name}
                    value={filter.user_id}
                    onChange={(e, value) => this.onFieldChange('user_id', value)}
                    renderInput={params => <TextField {...params} label="Created By" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li>
                  <label>Created Date</label>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="from_date"
                          placeholder="Date From"
                          format={fieldDateFormat}
                          fullWidth
                          disableFuture
                          InputLabelProps={{ shrink: true }}
                          value={filter.from_date ? filter.from_date : null}
                          onChange={(data, value) => this.onFieldChange('from_date', moment(data).format(backendDateFormat))}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="to_date"
                          placeholder="Date To"
                          format={fieldDateFormat}
                          fullWidth
                          disableFuture
                          InputLabelProps={{ shrink: true }}
                          value={filter.to_date ? filter.to_date : null}
                          onChange={(data, value) => this.onFieldChange('to_date', moment(data).format(backendDateFormat))}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </li>
              </ul>
              <Button size="small" onClick={this.applySearchFilter} color="primary" variant="contained">Apply Filter</Button>
              {Object.keys(removeEmptyKey(filter)).length ?
                <Button
                  size="small"
                  onClick={() => { this.setState({ filter: globalFilterObj }); this.props.globalRecordsSearchFn({ page: 1, per_page: 20, q: queryString.parse(this.props.location.search).q }) }}
                  style={isMobile ? {marginTop: '10px'}:{ marginLeft: '10px' }}
                  color="primary"
                  variant="outlined">Clear Filter
                </Button> : null
              }
            </div>

            {/* Below code is applicable only for mobile device. */}
            <div className="search-params-mobile">
              <p className='advance-filter'>To fine tune the result, apply advance filter by clicking on advanced filter Icon below.</p>
              <Paper style={{padding:'3px'}}>
                <IconButton size="small" color="primary">
                  <FilterListIcon  onClick={() => this.setState({open:true})}/>
                  <p style={{fontSize:'0.8rem' ,marginLeft:'10px'}}>Filter</p>
                </IconButton>
                <Drawer className='advance-filter-drawer' anchor={'right'} open={open} onClose={() =>  this.setState({open: false})}>
                  <ul className="list-unstyled">
                    <li>
                      <Autocomplete
                        id="file_type"
                        options={recordsFilter.file_type ? recordsFilter.file_type : []}
                        getOptionLabel={option => option.label}
                        value={filter.file_type}
                        onChange={(e, value) => this.onFieldChange('file_type', value)}
                        renderInput={params => <TextField {...params} label="Type" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </li>
                    <li>
                      <Autocomplete
                        id="search_only"
                        options={[{ label: 'Everything', value: 'none' }, { label: 'File and Folder Names', value: 'name' }, { label: 'File Content', value: 'text' }]}
                        getOptionLabel={option => option.label}
                        value={filter.search_only}
                        onChange={(e, value) => this.onFieldChange('search_only', value)}
                        renderInput={params => <TextField {...params} label="Search Within" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </li>
                    <li style={{ display: 'none' }}>
                      <Autocomplete
                        id="size"
                        options={[{ label: 'Any Size', value: '' }, { label: '0-1 MB', value: '' }, { label: '1-5 MB', value: '' }, { label: '1GB+', value: '' }]}
                        getOptionLabel={option => option.label}
                        value={filter.size}
                        onChange={(e, value) => this.onFieldChange('size', value)}
                        renderInput={params => <TextField {...params} label="Size" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </li>
                    <li>
                      <Autocomplete
                        id="assets"
                        options={recordsFilter.assets ? recordsFilter.assets.map(asset => { return { ...asset, unique_id: asset.asset_type + '$$' + asset.id } }) : []}
                        getOptionLabel={option => option.unique_name}
                        value={filter.assets}
                        onChange={(e, value) => this.onFieldChange('assets', value)}
                        renderInput={params => <TextField {...params} label="Assets" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </li>
                    <li>
                      <Autocomplete
                        id="user_id"
                        options={recordsFilter.user ? recordsFilter.user : []}
                        getOptionLabel={option => option.name}
                        value={filter.user_id}
                        onChange={(e, value) => this.onFieldChange('user_id', value)}
                        renderInput={params => <TextField {...params} label="Created By" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </li>
                    <li>
                      <label className='created-date-label'>Created Date</label>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              required
                              margin="normal"
                              id="from_date"
                              placeholder="Date From"
                              format={fieldDateFormat}
                              fullWidth
                              disableFuture
                              InputLabelProps={{ shrink: true }}
                              value={filter.from_date ? filter.from_date : null}
                              onChange={(data, value) => this.onFieldChange('from_date', moment(data).format(backendDateFormat))}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              required
                              margin="normal"
                              id="to_date"
                              placeholder="Date To"
                              format={fieldDateFormat}
                              fullWidth
                              disableFuture
                              InputLabelProps={{ shrink: true }}
                              value={filter.to_date ? filter.to_date : null}
                              onChange={(data, value) => this.onFieldChange('to_date', moment(data).format(backendDateFormat))}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    </li>
                  </ul>
                  <Button size="small" onClick={()=>{this.applySearchFilter(); this.setState({open:false})}} color="primary" variant="contained" style={{marginBottom:'5px'}}>Apply Filter</Button>
                  {Object.keys(removeEmptyKey(filter)).length ?
                    <Button
                      size="small"
                      onClick={() => { this.setState({ open: false }); this.setState({ filter: globalFilterObj }); this.props.globalRecordsSearchFn({ page: 1, per_page: 20, q: queryString.parse(this.props.location.search).q })}}
                      color="primary"
                      style={{marginBottom:'5px'}}
                      variant="outlined">Clear Filter
                    </Button> : null
                  }
                  <Button size="small" onClick={() => {this.setState({ open: false })}} color="primary" variant="outlined">Cancel</Button>
                </Drawer>
              </Paper>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            {!searchLoader && !recordsSearch.results.length ? <EmptyCollection title="No records found" /> : null}
            <div className="hel" style={{ height: window.innerHeight - 120, overflow: 'auto' }}>

              {/* <InfiniteScroll
                dataLength={recordsSearch.results ? recordsSearch.results.length : 0}
                next={() => this.moveNextPage()}
                hasMore={recordsSearch.results && recordsSearch.pagination ? recordsSearch.results.length < recordsSearch.pagination.total : false}
                loader={<h4 style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Loading...</h4>}
                endMessage={recordsSearch.results && recordsSearch.results.length !== 0 ? <p style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Yay! You have seen it all</p> : null}
              > */}
                {recordsSearch.results.map((item) =>
                  <GlobalSearchList
                    item={item}
                    searchTerm={queryString.parse(this.props.location.search).q}
                    fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                    onDownloadData={() => this.downloadGlobalSearchApi(this.props, item.asset_slug, item.asset_type, { uuid: item.uuid, type: item.type })}
                    onDeleteData={() =>
                      this.setState(prevState => ({
                        ...prevState,
                        bulkOperation: { modal: true, ids: item }
                      }))
                    }
                    onRenameData={() =>
                      this.setState(prevState => ({
                        ...prevState,
                        dataRename: {
                          modal: true,
                          data: item
                        }
                      }))
                    }
                  />
                )}
                <div style={{textAlign:"center", marginTop:"10px"}}>

                {recordsSearch.results && recordsSearch.pagination && recordsSearch.results.length < recordsSearch.pagination.total ?
                  <Button disabled={loadMore} size="small" onClick={this.moveNextPage} variant="outlined">
                    {
                      loadMore ? <CircularProgress size={24} />: 'Click to Load More'
                    }
                    </Button> : null
                }
                {recordsSearch.results && recordsSearch.results.length !== 0 && recordsSearch.results.length === recordsSearch.pagination.total ? <p style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Yay! You have seen it all</p> : null}
                </div>
              {/* </InfiniteScroll> */}
            </div>

          </Grid>
        </Grid>
        {dataRename.modal ?
          <RenameFileFolderModal
            dataRename={dataRename}
            toggleModalFn={() => this.setState({ dataRename: { modal: false, data: null } })}
            onChangeName={(value) =>
              this.setState(prevState => ({
                ...prevState,
                dataRename: {
                  ...prevState.dataRename,
                  data: {
                    ...prevState.dataRename.data,
                    name: value
                  }
                }
              }))
            }
            saveFileFolderName={this.saveFileFolderName}
          /> : null
        }
        {bulkOperation.modal ?
          <DeletePopUp
            modal={bulkOperation.modal}
            toggleModalFn={() =>
              this.setState(prevState => ({
                ...prevState,
                bulkOperation: {
                  ...prevState.bulkOperation,
                  modal: false,
                  ids: null
                }
              }))
            }
            title={'Delete'}
            content={<h4>{`Are you sure you want to delete?`}</h4>}
            triggerEventFn={this.onDeleteSearchData}
            confirmText={'Delete'}
          /> : null
        }
        {pageLoader || searchLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  recordsSearch: state.shareReducer.recordsSearch,
  recordsFilter: state.shareReducer.recordsFilter,
  searchLoader: state.shareReducer.searchLoader,
  loadMore: state.shareReducer.loadMore,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
    globalRecordsSearchFn: (query, loadType) => dispatch(globalRecordsSearchAc(ownProps, query, loadType)),
    globalRecordsSearchFilter: (query) => dispatch(globalFilterRecordsSearchAc(ownProps, query)),
    onUpdateRenameList: (data) => dispatch({ type: actions.RECORDS_RENAMED, payload: data }),
    onDeleteSearchRecords: (data) => dispatch({ type: actions.RECORDS_DELETE, payload: data })
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GlobalSearchResult))
