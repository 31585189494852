import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { assetTypeValues } from '../../../constants';
const ChangeOCRPriority = ({ocrPriority, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [priority, setPriority] = useState(ocrPriority.data.ocr_priority !== 4 ? { label: ocrPriority.data.ocr_priority === 1 ? 'High' : ocrPriority.data.ocr_priority === 2 ? 'Medium' : 'Low', value: ocrPriority.data.ocr_priority } : null)
  const changeOCRPriorityAsset = () => {
    let payload = {};
    payload = {
      ...payload,
      asset_details: [{ asset_type: ocrPriority.data.asset_type, asset_id: ocrPriority.data.id }],
      ocr_priority: priority.value
    }
    
    setLoading(true);
    globalPostService('records/v2/change-ocr-priority/', payload)
      .then(response => {
        if(checkApiStatus(response)) {
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          getResponseBack();toggleModalFn();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        setLoading(false);
      })
  }
  console.log('ocrPriority', ocrPriority)
  return(
    <Dialog
      open={ocrPriority.modal}
      onClose={toggleModalFn}
      className='records-dataroom-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        Change Asset OCR Priority: {assetTypeValues[ocrPriority.data.asset_type].label ? assetTypeValues[ocrPriority.data.asset_type].label: ''} {ocrPriority?.data?.serial_number}
      </DialogTitle>
      <DialogContent dividers={true} className="data-upload-modal">
        <div style={{width:'320px'}}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                options = {[{label:'High',value:1}, {label:'Medium',value:2}, {label:'Low',value:3},{label:'No Priority',value:4}]}
                getOptionLabel={option => option.label}
                id="ocr_priority"
                value={priority}
                onChange={(e, value) => setPriority(value)}
                renderInput={params => <TextField required {...params} label="Select Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button size='small' disabled={isLoading} color="primary" onClick={toggleModalFn}>Cancel</Button>
        { priority?.value ?
          <Button size='small' disabled={isLoading} color="primary" onClick={changeOCRPriorityAsset} variant="contained">
            { isLoading ? <CircularProgress size={24} />:'Save'}
          </Button>:null
        }
      </DialogActions>
    </Dialog>
  )
}
export default ChangeOCRPriority;
