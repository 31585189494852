import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, TextField, Breadcrumbs, Typography, Link, Tooltip } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AppsIcon from '@material-ui/icons/Apps';
import { Autocomplete } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';
import activeGraphIcon from '../../../assets/images/active-pie-chart.svg';
import inactiveGraphIcon from '../../../assets/images/inactive-pie-chart.svg';
import { trackActivity } from '../../../utils/mixpanel';
import Comments from './Comments';
import { checkPermission } from '../../../utils';
const DataRoomLayoutSetting = ({ fileInfo, match, viewMode, navigation, onChangeNav, sort_by, onSortDataRoom, onChangeMode, dataRoom, allAssets }) => {
  return (
    <Fragment>
      <div className="data-room-layout">
        <Grid container spacing={isMobile ? 1 : 3}>
          <Grid item xs={12} sm={6} md={6}>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb">
              {navigation && navigation.length ?
                navigation.map((nav, index) =>
                  navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                    <Link onClick={() => onChangeNav(nav)}>{nav.name}</Link>
                ) : null
              }
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ul className="list-inline flex-centered data-room-layout-options">
              {viewMode !== 3 ?
                <Fragment>
                  <li className="list-inline-item">Sort By:</li>
                  <li className="list-inline-item">
                    <Autocomplete
                      options={[{ label: 'A-Z', value: 'asc' }, { label: 'Z-A', value: 'desc' }]}
                      getOptionLabel={option => option.label}
                      value={sort_by === 'asc' ? { label: 'A-Z', value: 'asc' } : { label: 'Z-A', value: 'desc' }}
                      onChange={(e, value) => { value ? onSortDataRoom(value.value) : e.preventDefault() }}
                      renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </li>
                </Fragment> : null
              }
              <li className="list-inline-item">
                <span className="data-rm-switcher">
                  <span className={viewMode === 1 ? 'active' : ''} onClick={() => onChangeMode(1)}>
                    <Tooltip title="Grid View" arrow>
                      <AppsIcon color={viewMode === 1 ? 'primary' : 'default'} />
                    </Tooltip>
                  </span>
                  <span className={viewMode === 2 ? 'active' : ''} onClick={() => onChangeMode(2)}>
                    <Tooltip title="List View" arrow>
                      <ListAltIcon color={viewMode === 2 ? 'primary' : 'default'} />
                    </Tooltip>
                  </span>
                </span>
              </li>
              {checkPermission('records', 'comments_page', 'R') ?
                <li className="list-inline-item">
                  <Comments fileInfo={fileInfo} navigation={navigation} />
                </li> : null
              }
              {match.params.type === 'technical' && checkPermission('records', 'data_room', 'ANA') ?
                <li className="list-inline-item" onClick={() => { onChangeMode(3); trackActivity('Page Visited', { page_title: 'Analytics' }) }}>
                  {viewMode === 3 ?
                    <Tooltip title="Analytics" arrow>
                       <img src={activeGraphIcon} alt="Graph" style={{ cursor: 'pointer' }} />
                    </Tooltip> :
                    <Tooltip title="Analytics" arrow>
                       <img src={inactiveGraphIcon} alt="Graph" style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  }
                </li> : null
              }
            </ul>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}
export default withRouter(DataRoomLayoutSetting);
